.invite-radio-field {
  margin-bottom: 15px;
}
.invite-radio-field > label > div:nth-child(1) {
  margin: 5px 0;
  color: #9da2b0;
  line-height: 17px;
  font-size: 14px;
}
.invite-radio-field > label > div:nth-child(2) {
  display: flex;
}
.invite-radio-field input {
  margin-right: 6px;
}
.invite-radio-field > label > div:nth-child(2) {
  color: #495662;
  font-weight: 400;
}
.invite-radio-field > label > div:nth-child(2) > div > span {
  margin-right: 20px;
  vertical-align: middle;
  line-height: 19px;
  font-size: 16px;
}
.invite-radio-field > label > div:nth-child(2) > div > input {
  height: 16px;
  width: 16px;
  vertical-align: middle;
}
.invite-radio-field .empty {
  width: 16px;
  height: 16px;
  display: inline-flex;
  justify-content:center;
  align-items:center;
  border: 1px solid #495662;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 6px;
}
.invite-radio-field .filled {
  width: 10px;
  height: 10px;
  display: inline-flex;
  justify-content:center;
  align-items:center;
  background-color: #8DC9D4;
  border-radius: 50%;
}

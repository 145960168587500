.main-container {
  margin: 32px 135px 0;
}
.topbar {
  padding: 7px 15px;
}
.topbar > div {
  display: inline-block;
}
.topbar > div > img {
  width: 120px;
}
.topbar > div:nth-child(2) {
  font-size: 20px;
  display: inline-flex;
  font-size: 24px;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  vertical-align: top;
  width: calc(100vw - 270px);
  color: #495662;
}

@media (max-width: 1100px) {
  .main-container {
    margin: 32px 70px 0;
  }
}
@media (max-width: 800px) {
  .main-container {
    margin: 32px 40px 0;
  }
}
@media (max-width: 720px) {
  .main-container {
    margin: 32px 20px 0;
  }
  .stages-line {
    width: 20px;
    margin: 0 5px;
  }
  .topbar > div:nth-child(2) {
    font-size: 20px;
  }
  .topbar > div > img {
    width: 100px;
  }
  .topbar > div:nth-child(2) {
    width: calc(100vw - 230px);
  }
}
@media (max-width: 450px) {
  .topbar > div:nth-child(2) {
    font-size: 18px;
    white-space: nowrap;
  }
}
@media (max-width: 410px) {
  .topbar > div {
    display: block;
  }
  .topbar > div:nth-child(2) {
    text-align: center;
    width: 100%;
    margin: 5px 0 -20px;
  }
  .main-container {
    margin: 32px 10px 0;
  }
}
@media (max-width: 350px) {
  .main-container {
    margin: 32px 4px 0;
  }
}
.login-text-field {
  margin-bottom: 20px;
}
.login-text-field input {
  font-size: 16px;
  line-height: 19px;
  color: #495662;
  padding: 12px 16px;
  width: 370px;
  border: 1px solid #ccd2da;
  border-radius: 10px;
}
.login-text-field input:focus,
.login-text-field input:hover {
  border: 1px solid gray;
  outline: none;
}
.login-text-field > label > div {
  margin: 5px 0;
  color: #9da2b0;
  line-height: 17px;
  font-size: 14px;
}

.invite-text-field {
  margin-bottom: 15px;
}
.invite-client-form1 .invite-text-field {
  margin-bottom: 0;
}
.invite-text-field input {
  font-size: 16px;
  line-height: 19px;
  color: #495662;
  padding: 12px 16px;
  width: 360px;
  border: 1px solid #ccd2da;
  border-radius: 6px;
}
.invite-text-field input:focus,
.invite-text-field input:hover {
  border: 1px solid gray;
  outline: none;
}
.invite-text-field > label > div:nth-child(1) {
  margin: 5px 0;
  color: #9da2b0;
  line-height: 17px;
  font-size: 14px;
}
.invite-text-field-full {
  margin-bottom: 15px;
}
.invite-text-field-full input {
  font-size: 16px;
  line-height: 19px;
  color: #495662;
  padding: 12px 16px;
  width: 100% !important;
  border: 1px solid #ccd2da;
  border-radius: 6px;
}

.invite-text-field-full input:focus,
.invite-text-field-full input:hover {
  border: 1px solid gray;
  outline: none;
}
.invite-text-field-full > label > div:nth-child(1) {
  margin: 5px 0;
  color: #9da2b0;
  line-height: 17px;
  font-size: 14px;
}

/* .invite-inr-field input { */
.invite-mob-field input {
  display: inline-block;
  width: 265px;
  padding: 12px 16px;
  line-height: 19px;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 46px;
}
.invite-inr-field {
  position: relative;
}
/* .invite-inr-field input {
  width: 328px;
  padding-left: 5px;
} */

#mob,
#inr {
  line-height: 20px;
  display: inline-block;
  padding: 12px 16px;
  background-color: rgba(204, 210, 218, 0.2);
  margin: 0;
  border: 1px solid #ccd2da;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  color: #495662;
  font-size: 16px;
  white-space: nowrap;
  width: 95px;
}
#inr {
  width: 32px;
  padding: 12px;
}
.inr-padding-less input {
  /* padding-bottom: 13px; */
  /* height: 45px;
  
  padding-top: 12px; */
}
.inr-padding-less #inr {
  height: 46px;
  padding: 12.5px 12px 14px;
}

#mob > img {
  height: 19px;
  vertical-align: bottom;
  margin-right: 10px;
}

.red-text {
  margin-top: 5px;
  color: red;
}

.skip-text {
  font-size: 16px;
  color: #0b6c78;
  font-weight: bold;
  text-decoration: underline;
}

.invalid input,
.invalid select {
  border: 1px solid red;
}

#inr {
  background-color: transparent;
  border-right: none;
  padding-right: 0;
}

.not-editable {
  background-color: #495662;
  padding: 6px 16px;
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  border-radius: 4px;
  margin-top: 2px;
}

@media (max-width: 900px) {
  .invite-text-field input {
    width: 300px !important;
  }
  .invite-inr-field input {
    width: 300px !important;
  }
  .invite-mob-field input {
    width: 205px !important;
  }
  .not-editable {
    font-size: 12px !important;
    width: 300px !important;
    padding: 6px !important;
  }
}

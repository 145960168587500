.invite-select-field {
  margin-bottom: 15px;
}
.invite-select-field > label > div:nth-child(1) {
  margin: 5px 0;
  color: #9da2b0;
  line-height: 17px;
  font-size: 14px;
}
.select-css {
  display: block;
  font-size: 16px;
  /* font-weight: 500; */
  color: #495662;
  padding: 12px 16px;
  line-height: 19px;
  width: 360px;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #ccd2da;
  /* box-shadow: 0 1px 0 1px rgba(0,0,0,.04); */
  border-radius: 6px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
.select-css::-ms-expand {
  display: none;
}
.select-css:hover {
  border: 1px solid gray;
}
.select-css:focus {
  border: 1px solid gray;
  /* box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7); */
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}
.select-css option {
  font-weight: normal;
}

@media (max-width: 900px) {
  .select-css {
    width: 300px;
  }
}
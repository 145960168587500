*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
  font-family: ProximaNova !important;
}

.App {
  background-color: #F4F6F9;
  padding-bottom: 32px;
}
.video-verification {
  background-color: white;
  border-radius: 0 0 20px 20px;
  padding: 20px;
}


#onfido-mount {
  width: 512px;
  padding: 5px 20px;
}

#onfido-mount > div {
  width: 100%;
}

@media (max-width: 550px) {
  #onfido-mount {
    width: 400px;
    padding: 5px 2px;
  }
  #onfido-mount > div {
    width: 90%;
  }
}
@media (max-width: 480px) {
  #onfido-mount {
    width: 360px;
    padding: 5px 0;
    height: 90vh;
  }
  #onfido-mount > div {
    width: 360px;
    height: 90%;
  }
  .video-verification {
    padding: 20px 5px;
  }
  .main-container {
    margin: 32px 10px 0;
  }
}

@media (max-width: 380px) {
  #onfido-mount {
    width: 330px;
  }
  #onfido-mount > div {
    width: 330px;
  }
}

#invalidRequest {
  background-color: white;
  width: 1100px;
  padding: 60px 0;
  border-radius: 20px;
}
#invalidRequest > div:nth-child(3) > div {
  text-align: center;
}
.invalid-request {
  height: calc(100vh - 120px);
  display: flex;
  justify-content: center;
}

@media (max-width: 1200px) {
  #invalidRequest {
    width: 900px;
  }
  #invalidRequest > div:nth-child(3) {
    width: 600px !important;
  }
}

@media (max-width: 1000px) {
  #invalidRequest {
    width: 600px;
  }
  #invalidRequest > div:nth-child(3) {
    width: 500px !important;
  }
}
@media (max-width: 700px) {
  #invalidRequest {
    width: 500px;
    padding: 40px 0;
  }
  #invalidRequest > div:nth-child(3) {
    width: 400px !important;
  }
}
@media (max-width: 550px) {
  #invalidRequest {
    /* width: 350px; */
    padding: 20px;
  }
  #invalidRequest > div:nth-child(3) {
    width: 350px !important;
  }
}
@media (max-width: 410px) {
  #invalidRequest {
    padding: 20px 5px;
  }
  #invalidRequest > div:nth-child(3) {
    width: 320px !important;
  }
}

.invite-client-form3 {
  background-color: white;
  padding: 15px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.invite-client-form3 > div {
  width: 800px;
  margin: auto;
}
.form3-header {
  font-size: 22px;
  font-weight: 600;
  padding: 15px 0 10px;
  margin: 5px auto 10px;
  /* width: 150px; */
  text-align: center;
  white-space: nowrap;
}

.form3-upload {
  border: 1px dashed #0e91a0;
  border-radius: 16px;
  background-color: rgba(14, 145, 160, 0.03);
  width: 550px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.form3-upload > label {
  width: 100%;
  cursor: pointer;
  text-align: center;
}
.upload-icon > img {
  display: block;
  margin: 10px auto;
}
.form3-upload {
  color: #9da2b0;
}
.form3-upload span {
  color: #495662;
}
.form3-upload input[type="file"] {
  /* visibility: hidden; */
  /* opacity: 0; */
  display: none;
  cursor: pointer;
}

.form3-images {
  width: 550px !important;
  margin: 15px auto 30px !important;
  display: flex;
}

.form3-buttons {
  display: flex;
}
@media (max-width: 900px) {
  .form3-header, .form3-buttons {
    width: 650px !important;
  }
}

@media (max-width: 700px) {
  .form3-upload,
  .form3-images,
  .form3-header,
  .form3-buttons {
    width: 450px !important;
  }
  .form3-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media (max-width: 550px) {
  .form3-upload,
  .form3-images,
  .form3-header,
  .form3-buttons {
    width: 400px !important;
  }
}

@media (max-width: 450px) {
  .form3-upload,
  .form3-images,
  .form3-header,
  .form3-buttons {
    width: 300px !important;
  }
  .form3-images {
    flex-wrap: wrap;
  }
  .invite-client-form3 {
    padding: 0;
  }
}

.invite-client-form1 {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.invite-client-form1 > div {
  width: 740px;
  display: flex;
  justify-content: space-between;
}
.form1-header > div {
  font-size: 22px;
  font-weight: 600;
  padding: 5px 0 10px;
  margin: 5px auto 10px;
}

@media (max-width: 900px) {
  .invite-client-form1 > div {
    width: 620px;
  }
}

@media (max-width: 700px) {
  .invite-client-form1 > div {
    width: 500px;
  }
  .invite-client-form1 > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .invite-client-form1 [class^="invite-radio-field"] {
    width: 300px;
  }
}
@media (max-width: 550px) {
  .invite-client-form1 > div:not(div:nth-child(5)) {
    width: calc(100% - 50px);
  }
  .invite-client-form1 > div:nth-child(5) {
    width: 300px;
  }
  .form1-button > button,
  .goback-button > button {
    padding: 12px 0 !important;
    width: 300px !important;
    font-size: 16px !important;
  }
  .form1-header > div {
    font-size: 20px !important;
  }
}

.forms-text {
  display: flex;
  font-size: 16px;
  line-height: 22px;
  color: #495662;
  justify-content: center;
  align-items: center;
  margin: 20px 0 30px;
}

@media (max-width: 400px) {
  .forms-text {
    font-size: 14px;
  }
}

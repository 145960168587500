#confirmOrderModal {
  padding: 24px;
  width: 568px;
  height: 240px;
  border-radius: 10px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#confirmOrderModal > div:nth-child(1) {
  font-size: 24px;
  font-weight: bold;
  color: #495662;
  margin-bottom: 16px;
}
#confirmOrderModal > div:nth-child(2) {
  font-size: 16px;
  color: #495662;
  margin-bottom: 36px;
}

@media (max-width: 600px) {
  #confirmOrderModal {
    width: 400px;
    padding: 16px;
    height: 230px;
  }
}

@media (max-width: 420px) {
  #confirmOrderModal {
    width: 350px;
  }
}
@media (max-width: 380px) {
  #confirmOrderModal {
    width: 300px;
  }
}

.not-pending {
  height: calc(100vh - 120px);
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 24px;
}
.not-pending > div {
  text-align: center;
}
.not-pending > div > div:nth-child(1) {
  font-size: 40px;
  color: #0e91a0;
  font-weight: 700;
  display:none;
}
.not-pending > div > div:nth-child(2) {
  font-size: 22px;
  color: #495662;
  margin: 24px 0;
  font-weight: 500;
}
.not-pending > div > div:nth-child(3) {
  font-size: 18px;
  color: #797d89;
}

#thankyou {
  background-color: white;
  width: 1100px;
  padding: 60px 0;
  border-radius: 20px;
}
#thankyou > div:nth-child(3) > div {
  text-align: center;
}
.thank-you {
  height: calc(100vh - 120px);
  display: flex;
  justify-content: center;
}

@media (max-width: 1200px) {
  #thankyou {
    width: 900px;
  }
  #thankyou > div:nth-child(3) {
    width: 600px !important;
  }
}

@media (max-width: 1000px) {
  #thankyou {
    width: 600px;
  }
  #thankyou > div:nth-child(3) {
    width: 500px !important;
  }
}
@media (max-width: 700px) {
  #thankyou {
    width: 500px;
    padding: 40px 0;
  }
  #thankyou > div:nth-child(3) {
    width: 400px !important;
  }
}
@media (max-width: 550px) {
  #thankyou {
    /* width: 350px; */
    padding: 20px;
  }
  #thankyou > div:nth-child(3) {
    width: 350px !important;
  }
}
@media (max-width: 410px) {
  #thankyou {
    padding: 20px 5px;
  }
  #thankyou > div:nth-child(3) {
    width: 320px !important;
  }
}

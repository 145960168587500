#clientModal {
  padding: 10px 24px 0;
  width: 560px;
  height: 180px;
  border-radius: 10px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
#clientModal > div:nth-child(1) {
  margin: 0 auto;
  font-weight: bold;
  color: #495662;
}

@media (max-width: 600px) {
  #clientModal {
    width: 400px;
    padding: 10px;
    height: 220px;
  }
}

@media (max-width: 500px) {
  #clientModal {
    width: 350px;
  }
}
@media (max-width: 380px) {
  #clientModal {
    height: 230px;
    width: 310px;
  }
}
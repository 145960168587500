.invite-date-field {
  position: relative;
  margin-bottom: 25px;
}
.invite-date-field > label > div:nth-child(1) {
  margin: 5px 0;
  color: #9da2b0;
  line-height: 17px;
  font-size: 14px;
}
.dob {
  /* height: 40px; */
  width: 360px;
  font-size: 16px;
  line-height: 19px;
  padding: 10px 10px 10px 16px;
  border-radius: 6px;
  color: #495662;
  border: 1px solid #ccd2da;
}
.dob:focus,
.dob:hover {
  border: 1px solid gray;
}

.open-button {
  position: absolute;
  top: 33px;
  right: 2px;
  padding: 2px 6px 2px;
  background: #fff;
  pointer-events: none;
}

.open-button button {
  border: none;
  background: transparent;
}

@media (max-width: 900px) {
  .dob {
    width: 300px;
  }
}
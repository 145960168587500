.invite-client-form2 {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.invite-client-form2 > div {
  width: 740px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.multi-text-big {
  width: 360px !important;
}

@media (max-width: 900px) {
  .invite-client-form2 > div,
  .invite-text-field-full {
    width: 620px;
  }
  .multi-text-big {
    width: 300px !important;
  }
}
.invite-client-form2 > div:nth-child(2) > div {
  width: 100%;
}
@media (max-width: 700px) {
  .invite-client-form2 > div:nth-child(2) > div {
    width: auto;
  }
  .invite-client-form2 > div {
    width: 500px;
  }
  .invite-client-form2 > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .radio-text-big,
  .invite-text-field-full {
    width: 300px !important;
  }
  .multi-text-big {
    margin: -10px 0 10px 0;
  }
}
@media (max-width: 550px) {
  .invite-client-form2
    > div:not(div:nth-child(8), div:nth-child(9), div:nth-child(10)) {
    width: calc(100% - 50px);
  }
  #multiple-select-invite,
  .invite-inr-field,
  .invite-client-form2 > div:nth-child(8),
  .invite-client-form2 > div:nth-child(9),
  .invite-client-form2 > div:nth-child(10) {
    width: 300px !important;
  }
  .form2-button,
  .goback-button {
    margin: 10px;
  }
}

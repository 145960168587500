.stages {
  background-color: white;
  margin-top: 5px;
  padding: 20px;
  border-bottom: 1px solid #d8d8d8;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  justify-content: center;
}
.stages > div {
  display: flex;
  width: 860px;
  align-items: center;
  justify-content: center;
}
.stages-line {
  border: 1px solid #d8d8d8;
  width: 50px;
  margin: 0 10px;
}
.stages > div > div {
  display: flex;
  align-items: center;
  color: #495662;
  font-weight: 600;
}
.stages-white-text {
  color: #778192;
}
.stages-circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin-right: 5px;
}
.stages-circle > img {
  width: 18px;
  height: 18px;
}
.teal {
  background-color: #0e91a0;
  color: white;
}
.gray {
  background-color: #d8d8d8;
  color: #495662;
}

.stages > div > div:nth-child(odd) > div:nth-child(2) {
  white-space: nowrap;
}

@media (max-width: 1160px) {
  .stages > div > div [class*="hide-stage"] {
    display: none;
  }
}

@media (max-width: 420px) {
  .stages > div > div:nth-child(odd) > div:nth-child(2) {
    display: none;
  }
}

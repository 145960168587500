.invite-client-form4 {
  background-color: white;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.invite-client-form4 > div {
  width: 800px;
  margin: auto;
}
.invite-client-form4 > div:nth-child(3) {
  width: 768px;
}
.form4-header {
  font-size: 22px;
  font-weight: 600;
  padding: 5px 0 8px;
  margin: 5px auto 8px;
  text-align: center;
}
.tc-show {
  display: none;
}
.invite-client-form4 > div:nth-child(2) a {
  width: 50%;
}
@media (max-width: 1000px) {
  .invite-client-form4 > div {
    width: 700px !important;
  }
  .form4-header {
    width: 700px !important;
  }
}

@media (max-width: 900px) {
  .invite-client-form4 > div {
    width: 600px !important;
  }
  .form4-header {
    width: 600px !important;
  }
}
@media (max-width: 680px) {
  .invite-client-form4 > div {
    width: 500px !important;
  }
  .form4-header {
    width: 500px !important;
  }
}

@media (max-width: 600px) {
  .invite-client-form4 > div:nth-child(2) > div:nth-child(4),
  .invite-client-form4 > div:nth-child(2) > div:nth-child(5) {
    display: block !important;
  }
  .invite-client-form4 a {
    width: auto !important;
  }
}

@media (max-width: 550px) {
  .invite-client-form4 > div {
    width: 400px !important;
  }
  .form4-header {
    width: 400px !important;
  }
  .tc-show {
    display: inline;
  }
  .tc-hide {
    display: none;
  }
}
@media (max-width: 450px) {
  .invite-client-form4 > div {
    width: 300px !important;
  }
  .form4-header {
    width: 300px !important;
    font-size: 18px !important;
  }
}

.form4-span-clickable {
  color: #0e91a0;
  font-weight: 600;
  cursor: pointer;
}

.disabled {
  opacity: 0.5;
}

.form1-button {
  margin: 20px auto 30px;
}
.form1-button > button {
  background-image: linear-gradient(176.82deg, #0e91a0 0%, #233862 97.37%);
  width: 100%;
  border-radius: 29px;
  border-style: none;
  color: white;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  padding: 16px 32px;
}

.goback-button {
  margin: 20px 0 30px auto;
}
.goback-button > button {
  font-size: 18px;
  line-height: 25px;
  padding: 16px 32px;
  width: 100%;
  color: #0e91a0;
  border: 2px solid #0e91a0;
  border-radius: 29px;
  background-color: white;
  cursor: pointer;
  font-weight: bold;
}
.form2-button {
  margin: 20px auto 30px 20px;
}

.client-modal-button {
  margin: 0 auto;
}
.client-modal-button > button {
  padding: 17px 50px;
  border-radius: 25px;
  font-size: 14px;
  line-height: 17px;
  border-style: none;
  color: white;
  cursor: pointer;
  font-weight: bold;
  background-image: linear-gradient(176.82deg, #0e91a0 0%, #233862 97.37%);
}

.review-reject-order-button {
  margin-right: 20px;
}
.review-reject-order-button > button {
  padding: 14px 38px;
  white-space: nowrap;
  border-radius: 29px;
  font-size: 18px;
  border: 2px solid #0e91a0;
  color: #0e91a0;
  cursor: pointer;
  font-weight: bold;
  background-color: white;
}
.review-approve-order-button {
  margin-left: 20px;
}
.review-approve-order-button > button {
  padding: 16px 32px;
  white-space: nowrap;
  border-radius: 29px;
  font-size: 18px;
  border-style: none;
  color: white;
  cursor: pointer;
  font-weight: bold;
  background-image: linear-gradient(176.82deg, #0e91a0 0%, #233862 97.37%);
}

.confirm-modal-button {
  margin: 0 auto;
  width: 174px;
}
.confirm-modal-button > button {
  padding: 17px 68px;
  border-radius: 25px;
  font-size: 14px;
  line-height: 17px;
  border-style: none;
  color: white;
  cursor: pointer;
  font-weight: bold;
  background-image: linear-gradient(136.92deg, #0e91a0 4.49%, #0b6c78 96.39%);
}

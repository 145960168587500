.review-buy-order {
  background-color: white;
  border-radius: 20px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.review-buy-order > div {
  width: 568px;
}
/* .review-buy-order > div:nth-child(1) {
  font-size: 24px;
  font-weight: 700;
  color: #495662;
  margin-bottom: 16px;
} */

.review-buy-order > div:nth-child(1) {
  font-size: 16px;
  color: #495662;
  margin-bottom: 24px;
}

.review-buy-order > div:nth-child(2) > div,
.review-buy-order > div:nth-child(3) > div:nth-child(2) {
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 18px;
  /* 24px */
}
.review-buy-order > div:nth-child(2) > div > div:nth-child(1),
.review-buy-order > div:nth-child(3) > div:nth-child(2) > div:nth-child(1) {
  font-size: 16px;
  color: #495662;
}
.review-buy-order > div:nth-child(2) > div > div:nth-child(2),
.review-buy-order > div:nth-child(3) > div:nth-child(2) > div:nth-child(2) {
  font-size: 16px;
  color: #495662;
  font-weight: 700;
}

.review-buy-order > div:nth-child(3) > div:nth-child(1) {
  font-size: 18px;
  color: #495662;
  font-weight: 700;
  margin-bottom: 16px;
}

.review-buy-order > div:nth-child(4) {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  color: #495662;
  margin-bottom: 30px;
}

.review-buy-order > div:nth-child(6) {
  display: flex;
  justify-content: center;
  margin: 16px 0;
}

.review-buy-order > div:nth-child(7) {
  color: #797d89;
  font-size: 12px;
  display: flex;
  justify-content: center;
}

.review-buy-order
  > div:nth-child(2)
  > div:nth-child(1)
  > div:nth-child(2)
  > div {
  display: inline-block;
  vertical-align: top;
}
.review-buy-order
  > div:nth-child(2)
  > div:nth-child(1)
  > div:nth-child(2)
  > div:nth-child(2) {
  font-weight: 400;
  max-width: 450px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media (max-width: 650px) {
  .review-buy-order > div {
    width: 440px;
  }
  .review-buy-order
    > div:nth-child(2)
    > div:nth-child(1)
    > div:nth-child(2)
    > div:nth-child(2) {
    max-width: 300px;
  }
}
@media (max-width: 500px) {
  .review-buy-order > div {
    width: 340px;
  }
  .review-buy-order > div:nth-child(6) {
    flex-direction: column;
    align-items: center;
  }
  .review-buy-order > div:nth-child(6) > div {
    width: 340px;
    margin: 10px 0 !important;
  }
  .review-buy-order > div:nth-child(6) > div > button {
    width: 340px;
    display: flex;
    justify-content: center;
  }
  .review-buy-order
    > div:nth-child(2)
    > div:nth-child(1)
    > div:nth-child(2)
    > div:nth-child(2) {
    max-width: 220px;
  }
}
@media (max-width: 390px) {
  .review-buy-order > div {
    width: 300px;
  }
  .review-buy-order > div:nth-child(6) > div {
    width: 300px;
  }
  .review-buy-order > div:nth-child(6) > div > button {
    width: 300px;
  }
  .review-buy-order
    > div:nth-child(2)
    > div:nth-child(1)
    > div:nth-child(2)
    > div:nth-child(2) {
    max-width: 190px;
  }
}

.review-buy-order > div:nth-child(3) {
  display: none;
}

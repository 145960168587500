.docImage {
  color: #495662;
  position: relative;
  margin-right: 10px;
}
.docImage > div:nth-child(2) > img {
  width: 192px !important;
  height: 108px !important;
}
.docImage > div:nth-child(1) {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  background-color: black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.docImage > div:nth-child(3) {
  text-align: center;
}
